import React from 'react'
import Taximage from '../images/shutterstock_642415924.jpg'
import Taximagetwo from '../images/shutterstock_623855219.jpg'

import BackgroundImageTwo from '../images/shutterstock_1090445729.jpg'
import Mario from '../images/mario.jpg'

import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import ImageText from '../components/imagetext'
import ConsultingAppointment from '../components/consultingappointment'
import Testimonals from '../components/testimonials'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

const AboutPage = () => (
  <Layout>
    <div>
      <HeaderSubsite
        headline="Über uns"
        subline="Alles was Sie über uns wissen müssen"
        bg={BackgroundImageTwo}
      />
      <ImageText
        bg={Taximagetwo}
        subline="Gute Kommunikation entsteht im Dialog."
        headline="Gute Steuerberatung ebenfalls."
      >
        <p>
          Seit mehr als 25 Jahren beraten wir nationale und internationale
          mittelständische Unternehmen aller Rechtsformen, Existenzgründer und
          Start-Ups sowie Freiberufler, Sportler und Künstler in sämtlichen
          betriebswirtschaftlichen und steuerrechtlichen Belangen.
          <br />
          <br />
          Doch was unterscheidet unsere Kanzlei von anderen? Ist es unsere
          langjährige Expertise für ganz verschieden Branchen? Das tiefe
          Verständnis für den Mittelstand? Oder die Tatsache, dass wir eine
          Einkommensteuererklärung mit genau so viel Energie und Ehrgeiz in
          Angriff nehmen wie die mehrseitigen Zahlenkolonnen einer GmbH & Co.
          KG?
          <br />
          <br />
          Ein Grund für unser dynamisches Wachstum gerade in den letzten Jahren
          ist mit Sicherheit die Art und Weise, wie wir mit unseren Mandanten
          umgehen. Wir hören genau hin, fragen nach und kommunizieren klar und
          ohne Umwege. Verbindlich. Vertrauensvoll. Verständlich. So verstehen
          und verständigen wir uns innerhalb unseres Teams. Und im Dialog mit
          unseren Mandanten. Auf Basis dieser Werte schaffen wir eine
          wohltemperierte Atmosphäre, die von Respekt und gegenseitiger
          Offenheit gekennzeichnet ist.
          <br />
          <br />
          Auch als Privatperson sind Sie bei der Steuerberatung Ruberto in den
          allerbesten Händen. Ganz gleich, ob Sie uns Ihre private Vermögens-
          und Liquiditätsplanung anvertrauen oder ob Sie uns als anspruchsvoller
          Privatmandant mit Ihrer jährlichen Einkommensteuererklärung
          beauftragen: Wir sind zu 100% für Ihre Belange da. Aktiv.
          Vorausschauend. Und voller Leidenschaft.
          <br />
          <br />
          Dafür steht das gesamte Team der Steuerkanzlei Ruberto.
        </p>
      </ImageText>
      <ConsultingAppointment
        title="Sie wünschen ein Beratungstermin - nichts einfacher als das..."
        cta="Termin vereinbaren"
        bgcolor="#d3a04e"
        color="#fff"
      />
      <ImageText
        bg={Taximage}
        direction="row-reverse"
        subline="100% Leidenschaft"
        headline="für unsere Mandanten."
      >
        <p>
          Jede Steuer- und Wirtschaftsberatung ist immer nur so gut, wie das
          Team, das hinter dieser Leistung steht. Bei uns finden Sie erfahrene
          Steuerberater der unterschiedlichsten Disziplinen, aber auch
          qualifizierte Steuerfachwirte, Bilanzbuchhalter, Dipl.-Betriebswirte
          (FH), Steuerfachangestellte und Lohnbuchhalter.
          <br />
          <br />
          „Wir“ – das ist aber nicht nur das Team der RSD Steuerberatung Mario
          Ruberto, sondern auch ein starkes Versprechen, das wir unseren
          Mandanten entgegen bringen: Wir wollen nicht nur verwalten, sondern
          Sie aktiv und vorausschauend voranbringen. Mit professioneller
          Beratung auf höchstem Niveau. Fundierter Expertise. 100% Leidenschaft
          auf allen Ebenen. Und weil die Erde nicht still steht, sind
          regelmäßige Schulungs- und Fortbildungsmaßnahmen bei uns
          selbstverständlich.
          <br />
          <br />
          Ein weiteres Versprechen: Wir reden kein Fach-Chinesisch, sondern
          erklären Ihnen auch komplexe Sachverhalte verständlich, anschaulich
          und in aller Ruhe. Wir gehen auf Ihre individuellen Fragen oder
          Bedenken jederzeit ein. Kurz: Wir setzen uns mit unserem jungen und
          dennoch zugleich hochqualifizierten Team für Ihre Belange so ein, als
          wären sie unsere eigenen.
          <br />
          <br />
          Diese engagierte Haltung spiegelt sich nicht nur in einem
          außergewöhnlich guten Betriebsklima, sondern ebenso in der
          partnerschaftlichen Zusammenarbeit mit unseren Mandanten.
          <br />
          <br />
          Profitieren Sie als Mandant unserer Kanzlei zudem von einem
          erstklassigen Netzwerk, das wir sowohl zu Wirtschaftsprüfern wie auch
          zu Rechtsanwälten pflegen. Ihr Plus: Durch die Kombination aus eigenem
          Know-how und Kompetenz-Netzwerk sind wir in der bevorzugten Lage,
          unsere Philosophie einer ganzheitlichen, nationalen wie
          internationalen Mandantenbetreuung konsequent in die Tat umsetzen zu
          können.
        </p>
      </ImageText>
      <Testimonals />
      <ImageText bg={Mario} subline="Inhabergeführte" headline="Steuerkanzlei">
        <p>
          Studium der Betriebswirtschaftslehre mit Schwerpunkt Steuerrecht in
          München. Anschließend mehrjährige Tätigkeit in verschiedenen,
          renommierten Steuerberatungs- und Wirtschaftsprüfungsgesellschaften.
          2006 Prüfung zum Steuerberater. Gründung einer eigenen Steuerkanzlei
          in München. Ganzheitliche Betreuung von nationalen und internationalen
          Unternehmen.
          <br />
          <br />
          In diesem Sinne - ich freue mich bereits jetzt schon Sie auf diesen
          Weg kennenzulernen.
          <br />
          Ich verspreche Ihnen bereits jetzt schon eine angenehme
          Zusammenarbeit. <br />
          <br />
          Mit freundlichen Grüssen
          <br /> Mario Ruberto
        </p>
      </ImageText>
      <Footer />
      <SubFooter />
    </div>
  </Layout>
)

export default AboutPage
